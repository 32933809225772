import { ProgressButtons } from "./progressButtons";
import { PollQuestion } from "@/types/poll";

interface AcceptFollowUpInterface {
    previous: () => void;
    next: () => void;
    coverImage: string;
    question: PollQuestion;
}

export default function AcceptFollowUp({
    previous,
    next,
    coverImage,
    question,
}: AcceptFollowUpInterface) {
    return (
        <>
            <div className="aspect-w-10 aspect-h-6 block w-full overflow-hidden bg-gray-100 ">
                <img src={coverImage} className="object-cover" alt="" />
            </div>
            <div className="p-10 flex flex-col h-full">
                <span className="text-2xl font-medium pb-2">
                    {question.question}
                </span>
                <ProgressButtons
                    previous={previous}
                    next={next}
                    previousLabel="Skip"
                    nextLabel="Add my opinion"
                />
            </div>
        </>
    );
}
