import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ProgressButtons } from "./progressButtons";
import { Checkbox } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import { optionStyles } from "./optionStyles";
function StyledCheckbox({ enabled, setEnabled, title }) {
    return (
        <Checkbox
            checked={enabled}
            onChange={(included) => setEnabled(title, included)}
            className={`${optionStyles} gap-x-4`}
        >
            <div className="group block size-6 rounded border-2 bg-white group-data-[checked]:bg-voice group-data-[checked]:border-voice group-hover:border-voice">
                <svg
                    className="stroke-gray-200 opacity-0 group-data-[checked]:opacity-100"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        className="stroke-white"
                        d="M3 8L6 11L11 3.5"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            {title}
        </Checkbox>
    );
}

interface CheckboxOptionsInterface {
    options: string[];
    selected: string[];
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    previous: () => void;
    next: () => void;
    setProgress: (e: number) => void;
}

export default function CheckboxOptions({
    options,
    selected,
    setSelected,
    previous,
    next,
    setProgress,
}: CheckboxOptionsInterface) {
    const [error, setError] = useState(false);

    const toggleOption = (key, included) => {
        if (included) {
            setSelected((prev) => [...prev, key]);
        } else {
            setSelected((prev) => prev.filter((item) => item !== key));
        }
    };

    useEffect(() => {
        if (selected?.length) {
            setProgress(0.5);
            setError(false);
        } else {
            setProgress(0);
        }
    }, [selected]);
    return (
        <>
            <span className="text-gray-600 text-sm">
                <FormattedMessage id="Multiple selection" />
            </span>

            <div className="flex flex-col gap-y-3 mt-3">
                {options.map((item) => (
                    <StyledCheckbox
                        title={item}
                        enabled={selected?.includes(item)}
                        setEnabled={toggleOption}
                        key={`${item}-${uuidv4()}`}
                    />
                ))}
            </div>
            {error && (
                <p className="text-red-600 text-sm">
                    <FormattedMessage id="Please select an option" />
                </p>
            )}
            <ProgressButtons
                previous={previous}
                nextLabel="Submit"
                next={() => {
                    if (selected?.length) {
                        next();
                    } else {
                        setError(true);
                    }
                }}
            />
        </>
    );
}
