import { Radio, RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ProgressButtons } from "./progressButtons";
import { optionStyles } from "./optionStyles";
import { v4 as uuidv4 } from "uuid";

interface RadioOptionsInterface {
    options: string[];
    selected?: string;
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
    previous: () => void;
    next: () => void;
    setProgress: (e: number) => void;
}

export default function RadioOptions({
    selected,
    setSelected,
    options,
    previous,
    next,
    setProgress,
}: RadioOptionsInterface) {
    const [error, setError] = useState(false);
    useEffect(() => {
        if (selected) {
            setError(false);
            setProgress(0.5);
        }
    }, [selected]);
    return (
        <>
            <span className="text-gray-600 text-sm">
                <FormattedMessage id="Single selection" />
            </span>
            <RadioGroup
                value={selected}
                onChange={(item) => setSelected([item])}
                aria-label="Response"
                className="flex flex-col gap-y-3 mt-3"
            >
                {options.map((option, index) => (
                    <Radio
                        key={`${index}-${uuidv4()}`}
                        value={option}
                        className={optionStyles}
                    >
                        <div className="flex w-full">
                            <div className="group flex size-6 items-center justify-center rounded-full border-2 bg-white group-data-[checked]:border-voice group-hover:border-voice">
                                <span className="invisible size-3 rounded-full bg-voice group-data-[checked]:visible" />
                            </div>
                            <div className="ml-4">
                                <p>{option}</p>
                            </div>
                        </div>
                    </Radio>
                ))}
            </RadioGroup>
            {error && (
                <p className="text-red-600 text-sm">
                    <FormattedMessage id="Please select an option" />
                </p>
            )}
            <ProgressButtons
                previous={previous}
                nextLabel="Submit"
                next={() => {
                    if (selected) {
                        next();
                    } else {
                        setError(true);
                    }
                }}
            />
        </>
    );
}
