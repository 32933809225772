import Button from "@/components/common/buttons/button";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

export default function CompletedPage({
    reset,
    setProgress,
}: {
    reset: () => void;
    setProgress: (progress: number) => void;
}) {
    useEffect(() => {
        setProgress(1);
    }, []);
    return (
        <div className="flex flex-col min-h-full flex-grow justify-between">
            <div />
            <div className="h-full flex items-center">
                <div className="flex">
                    <div>
                        <h3 className="text-2xl font-medium">
                            <FormattedMessage id="Thank you for your participation" />
                        </h3>
                        <p className="text-gray-500 text-sm pt-2">
                            <FormattedMessage id="You can close this poll now" />
                        </p>
                    </div>
                    <div className="text-6xl">🎉</div>
                </div>
            </div>
            <Button
                id="back"
                color="voice"
                className="w-full justify-center"
                onClick={reset}
            >
                <FormattedMessage id="Close" />
            </Button>
        </div>
    );
}
